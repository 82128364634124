<template>
	<div>
		<a-modal title="随访详情" :width="800" :visible="visible"  @cancel="handleCancel">
      <template>
        <a-descriptions title="基础信息" :column="3">
          <a-descriptions-item v-if="item.key != 'drugs' && item.type != 'hidden'" label="" v-for="(item,index) in detail.content">{{item.title}}：{{item.value}}</a-descriptions-item>
        </a-descriptions>
        <a-descriptions title="在用药物" :column="1" v-if="drugs.key == 'drugs'" label="" v-for="(drugs,index) in detail.content">
          <a-descriptions-item v-for="(item_g,index) in drugs.item">
            <span class="mr10" v-if="item_g[0].value">{{item_g[0].title}} : {{item_g[0].value}} </span>
            <span class="mr10" v-if="item_g[1].value">{{item_g[1].title}} : {{item_g[1].value}} </span>
            <span class="mr10" v-if="item_g[2].value">{{item_g[2].title}} : {{item_g[2].value}} </span>
          </a-descriptions-item>
        </a-descriptions>

      </template>
		</a-modal>

	</div>
</template>

<script>
	export default {
    props: {
      visible:{
        type:Boolean,
        default:false,
      },
      access_id:{
        type:Number,
        default:0,
      }

    },
		data() {
			return {
				loading: false,
        confirmLoading: false,
        detail: {},
			}
		},
		created() {
      this.loaddata();
		},
		methods: {

      loaddata(){
        this.$http.api('admin/getAccessDetail',{
          access_id:this.access_id,
        }).then(res=>{
          this.loading=false;
          this.detail = res.detail;
        }).catch(res=>{
          this.loading=false;
        })
      },
			
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},
	
		}
	}
</script>

<style>
  .member-detail-face{width:100px;height: auto;float: left;}
</style>
