<template>
    <div>
        <div class="ft20 cl-black cl-main ftw600">随访记录</div>

        <div class="mt20">
            <div class="form-search-box ">
                <a-form layout="inline">
                    <a-form-item label="长者/随访人员">
                        <a-input v-model="search.name" placeholder="请输入姓名"></a-input>
                    </a-form-item>

                    <a-form-item label="随访时间">
                        <a-range-picker @change="changeAppointTime" v-model="search.time" format="YYYY-MM-DD"
                                        valueFormat="YYYY-MM-DD"/>
                    </a-form-item>

                    <a-form-item>
                        <a-button @click="searchAct" type="primary">查询</a-button>
                        <a-button @click="cancelAct" class="ml10">取消</a-button>
                    </a-form-item>
                </a-form>
            </div>
        </div>

        <div class="mt20 pd20 bg-w">
            <div class="wxb-table-gray mt20">
                <a-table rowKey="access_id" :columns="columns" :pagination="pagination" @change="handleTableChange"
                         :data-source="datas" :loading="loading">

                    <template slot="action" slot-scope="record,index">
                        <div class="flex center">
                            <a-dropdown placement="bottomRight">
								<span class="more-act">
									<i class="iconfont iconmore_gray"></i>
								</span>
                                <a-menu slot="overlay">
                                    <a-menu-item>
                                        <a class="menu-act" href="javascript:;" @click="viewAccess(record)">
                                            <span>详情</span>
                                        </a>
                                    </a-menu-item>
                                </a-menu>
                            </a-dropdown>
                        </div>
                    </template>
                </a-table>
            </div>
        </div>

        <div v-if="importAccessLoading">
            <access-detail :visible="importAccessLoading" :access_id="access_id" @cancel="cancelFrom"></access-detail>
        </div>

    </div>
</template>

<script>
import {listMixin} from '../../common/mixin/list.js';
import accessDetail from '../member/components/accessDetail.vue';
import moment from "moment/moment";

export default {
    mixins: [listMixin],
    components: {
        accessDetail,
    },
    data() {
        return {
            importAccessLoading: false,
            loading: false,
            editloading: false,
            confirmLoading: false,
            access_id: 0,
            pagination: {
                current: 1,
                pageSize: 10, //每页中显示10条数据
                total: 0,
            },
            search: {
                name: '',
                time: [],
            },
            level: [],
            columns: [
                {title: '编号', dataIndex: 'access_id', align: 'center'},
                {title: '站点', dataIndex: 'site_name', align: 'left', ellipsis: true},
                {title: '随访长者', dataIndex: 'elder_name', align: 'left', ellipsis: true},
                {title: '随访人员', dataIndex: 'personnel', align: 'left', ellipsis: true},
                {title: '随访时间', dataIndex: 'access_time_format', align: 'left', ellipsis: true},
                {title: '随访状态', dataIndex: 'status_txt', align: 'left', ellipsis: true},
                {title: '随访备注', dataIndex: 'access_notes', align: 'left', ellipsis: true},
                {title: '操作', key: 'action', align: 'center', scopedSlots: {customRender: 'action'}}
            ],
            datas: [],
            member: {},
            member_id: 0,
            detail: {},
            qrCodeSize: 200,
        }
    },
    methods: {
        getLists() {
            if (this.$route.query && this.isPageInit) {
                if (this.$route.query.inquire === 'toDay') {
                    this.search.time = [
                        moment().format('YYYY-MM-DD'),
                        moment().format('YYYY-MM-DD')
                    ];
                }
                if (this.$route.query.inquire === 'thisMonth') {
                    this.search.time = [
                        moment().subtract(30, 'days').format('YYYY-MM-DD'),
                        moment().format('YYYY-MM-DD')
                    ];
                }
            }
            if (this.loading == true) return;
            this.loading = true;
            this.$http.api('admin/getAccessList', {
                name: this.search.name,
                time: this.search.time,
                limit: this.pagination.pageSize,
                page: this.pagination.current,
            }).then(res => {
                this.pagination.total = res.total;
                this.pagination.showTotal = (total) => "共" + res.total + "条";
                this.datas = res.list;
                this.level = res.level;
                this.loading = false;
            }).catch(res => {
                this.loading = false;
            })
        },
        handleTableChange(pagination, filters, sorter) {
            this.pagination.current = pagination.current;
            this.getLists();
        },
        cancelFrom() {
            this.importAccessLoading = false;
        },
        handleCancel() {
            this.visible = false;
        },
        changeAppointTime(value) {
            this.search.time = value;
        },
        viewAccess(record) {
            this.access_id = record.access_id;
            this.importAccessLoading = true;
        },
    }
}
</script>

<style>
.member-index-face {
    width: 44px;
    height: 44px;
    border-radius: 44px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
    border: 2px solid #FFFFFF;
}

.member-index-action {
    width: 32px;
    height: 32px;
    border-radius: 32px;
    background: #FFFFFF;
    border: 1px solid #EBEDF5;
    text-align: center;
    line-height: 32px;
}
</style>
